/**
 * THIS FILE IS GENERATED, DO NOT MODIFY THEM...
 * contact @Miloslav Vlach
 */
import axios, { AxiosResponse } from 'axios';
import { GetIdTokenClaimsOptions, IdToken } from '@auth0/auth0-spa-js';

 export interface MessageResponseOutDTO {
    path: string;
    scope: ScopeEnum;
    type: TypeEnum;
    message: string;
}

export interface ResponseResult {
    success: boolean;
    errorType?: ErrorTypeEnum;
    messages: MessageResponseOutDTO[];
    payload?: any;
}

export interface StoreToPCloudInDTO {
    documentId: number;
    documentType: DocumentTypeEnum;
}

export interface SetLabelsInDTO {
    objectType: ObjectTypeEnum;
    objectId: number;
    labels: number[];
}

export interface RestMenuItemChangeVisibilityInDTO {
    menuItemId: number;
    visible: boolean;
}

export interface PairInvoiceToContractInDTO {
    invoiceId: number;
    resourceContractId: number;
}

export interface UpdateInvoiceAttachmentLoVInDTO {
    uuid: string;
    fileName: string;
}

export interface UpdateInvoiceCustomerLoVInDTO {
    id: number;
    name: string;
}

export interface UpdateInvoiceInDTO {
    id: number;
    customer: UpdateInvoiceCustomerLoVInDTO;
    applicationId: string;
    attachment?: UpdateInvoiceAttachmentLoVInDTO;
    orderId?: string;
    dueDate: string;
    createdAt: string;
    deliveryId?: string;
    taxableAt: string;
    variableSymbol: string;
    specificSymbol?: string;
    constantSymbol?: string;
    rows: UpdateInvoiceLineInDTO[];
    accountNumber: string;
    vatComputeMode: VatComputeModeEnum;
    invoiceNote?: string;
    transferredTaxLiability: boolean;
    currency: string;
    suppliedInvoiceId?: number;
    invoiceType: InvoiceTypeEnum;
    paymentType: PaymentTypeEnum;
}

export interface UpdateInvoiceLineInDTO {
    amount: number;
    unit: string;
    description: string;
    pricePerUnit: number;
    vatPercentage: number;
}

export interface ContactControllerInDTO {
    name: string;
    tin: string;
    vat?: string;
    streetNr: string;
    city: string;
    zipCode: string;
    country: string;
    accountNumber: string;
}

export interface ContactControllerOutDTO {
    id: number;
    name: string;
    tin: string;
    vat?: string;
    streetNr: string;
    city: string;
    zipCode: string;
    country: string;
    accountNumber: string;
}

export interface ChangeCompanyInDTO {
    city: string;
    companyName: string;
    country: string;
    streetNr: string;
    tin: string;
    vat?: string;
    zipCode: string;
    vatPayer: VatPayerEnum;
    phone?: string;
    url?: string;
}

export interface AttachmentInDTO {
    dataURL: string;
    fileName: string;
}

export interface AttachmentOutDTO {
    uuid: string;
    fileName: string;
}

export interface FullNameInDTO {
    fullName: string;
}

export interface ItemToPay {
    itemId: string;
    count: number;
}

export interface PartialPay {
    itemsToPay: ItemToPay[];
}

export interface OrderItems {
    orderItemsIds: string[];
}

export interface IdentityInDTO {
    fullName: string;
    email: string;
    companyName?: string;
    tin?: string;
    vat?: string;
    streetNr?: string;
    city?: string;
    zipCode?: string;
    slug: string;
}

export interface IdentityOutDTO {
    slug: string;
    used: boolean;
}

export interface WorkspaceNameInDTO {
    workspaceName: string;
}

export interface AddWorkspaceOutDTO {
    uuid: string;
}

export interface DownloadAttachmentGenerateLinkInDTO {
    origin: string;
    fileUUID: string;
}

export interface GuestEntryOutDTO {
    resident: boolean;
    title: string;
    firstName: string;
    lastName: string;
    category: CategoryEnum;
    dateOfBirth?: string;
    idType: IdTypeEnum;
    idNumber: string;
    streetNr: string;
    city: string;
    zipCode: string;
    countryCodeISO3: string;
}

export interface GuestInfoInDTO {
    registeredGuests: GuestEntryOutDTO[];
}

export interface ContractInInDTO {
    contractSince: string;
    contractTill: string;
    resources: ResourceReservationInDTO[];
}

export interface ReservationInDTO {
    fullName: string;
    email?: string;
    phone?: string;
    totalPrice?: number;
    contract: ContractInInDTO;
    guestWillFillElectronicCheckIn: boolean;
    plannedArrival: string;
}

export interface ResourceReservationInDTO {
    resourceUUID: string;
    guestsCount: number;
}

export interface CheckReservationInDTO {
    resourceGroupUUID: string;
    guests: number;
    localDateSince: string;
    localDateTill: string;
}

export interface CheckReservationOutDTO {
    resourceUUID: string;
    resourceName: string;
    bookedGuests: number;
    maximalGuest: number;
    localDateTill: string;
    localDateSince: string;
    totalPrice: number;
    nights: number;
    mainImage?: string;
}

export interface UpdateDurationAndGuestsInDTO {
    since: string;
    till: string;
    guets: number;
    recomputePrice: boolean;
}

export interface AttachmentLoVInDTO {
    uuid: string;
    fileName: string;
}

export interface SupplierInvoiceInDTO {
    supplier: SupplierLoVInDTO;
    applicationId: string;
    attachment?: AttachmentLoVInDTO;
    orderId?: string;
    dueDate: string;
    createdAt: string;
    deliveryId?: string;
    originalNumber: string;
    taxableAt: string;
    variableSymbol: string;
    specificSymbol: string;
    constantSymbol: string;
    rows: SupplierInvoiceLineInDTO[];
    accountNumber: string;
    vatComputeMode: VatComputeModeEnum;
    transferredTaxLiability: boolean;
    currency: string;
}

export interface SupplierInvoiceLineInDTO {
    amount: number;
    unit: string;
    description: string;
    pricePerUnit: number;
    vatPercentage: number;
}

export interface SupplierLoVInDTO {
    id: number;
    name: string;
}

export interface RestCreateUpdateMenuItemIn {
    id?: number;
    name: string;
    altName?: string;
    description?: string;
    altDescription?: string;
    imageURI?: string;
    price: number;
    menuItemVATGroup: MenuItemVATGroupEnum;
    categoryId?: number;
    amount: number;
    unit: string;
    visible: boolean;
}

export interface PriceItemInDTO {
    name: string;
    unit: string;
    vat: number;
    price: number;
    code: string;
}

export interface PriceItemOutDTO {
    id: number;
    name: string;
    unit: string;
    vat: number;
    price: number;
    code: string;
}

export interface PaymentFindInDTO {
    direction: DirectionEnum;
    amount?: number;
    account?: string;
    vs?: string;
}

export interface BankItemOutDTO {
    bankItemId: number;
    amount: number;
    account?: string;
    vs?: string;
    date: string;
    disponibleAmount: number;
}

export interface PassivateContractInDTO {
    passivate: boolean;
}

export interface ListBankItemsFilterInDTO {
    myAccountId?: number;
    amount?: number;
    account?: string;
    comment?: string;
    type?: string;
}

export interface ListBankItemsOutDTO {
    amount: number;
    date: string;
    details?: string;
    userIdentification?: string;
    paidAt?: string;
    preprocessedAccount?: string;
    type: TypeEnum;
}

export interface InvoicePaymentLinkInDTO {
    invoiceId: number;
    bankItemId: number;
}

export interface ExpensePaymentLinkInDTO {
    supplierInvoiceId: number;
    bankItemId: number;
}

export interface CustomerLoVInDTO {
    id: number;
    name: string;
}

export interface InvoiceInDTO {
    customer: CustomerLoVInDTO;
    applicationId: string;
    attachment?: AttachmentLoVInDTO;
    orderId?: string;
    dueDate: string;
    createdAt: string;
    deliveryId?: string;
    taxableAt: string;
    variableSymbol: string;
    specificSymbol?: string;
    constantSymbol?: string;
    rows: InvoiceLineInDTO[];
    accountNumber: string;
    vatComputeMode: VatComputeModeEnum;
    invoiceNote?: string;
    transferredTaxLiability: boolean;
    currency: string;
    suppliedInvoiceId?: number;
    invoiceType: InvoiceTypeEnum;
    paymentType: PaymentTypeEnum;
}

export interface InvoiceLineInDTO {
    amount: number;
    unit: string;
    description: string;
    pricePerUnit: number;
    vatPercentage: number;
}

export interface CreateInvoiceOutDTO {
    id: number;
}

export interface GetGalleryDataInDTO {
    uuids: string[];
}

export interface GalleryItemOutDTO {
    uuid: string;
    name: string;
    contentType: string;
    urlData: string;
}

export interface GetGalleryDataOutDTO {
    gallery: GalleryItemOutDTO[];
}

export interface ComputationSummaryInDTO {
    supplierId?: number;
    customerId?: number;
}

export interface FacturationSummeryOutDTO {
    invoices: Line[];
    invoiceTotal: number;
    expenses: Line[];
    expendesTotal: number;
    payments: PaymentLine[];
    paymentTotal: number;
}

export interface Line {
    data: string;
    name: string;
    total: number;
    variableSymbol?: string;
    paid: string;
}

export interface PaymentLine {
    date: string;
    name: string;
    total: number;
    variableSymbol?: string;
    paired?: boolean;
}

export interface AdditionalAttributes {
    id?: number;
    name?: string;
    category?: string;
}

export interface CreateOrUpdateCarInDTO {
    id?: number;
    name: string;
    internalName?: string;
    carType: CarTypeEnum;
    carDescription?: string;
    stkTill?: string;
    emissionTill?: string;
    manofacturer: string;
    locality?: string;
    maximalSpeed: number;
    maxPersonRide: number;
    maxPersonSleep: number;
    fuelType: FuelTypeEnum;
    engineVolume: number;
    enginePower: number;
    yearOfProduction: number;
    maximalWeight: number;
    plainWeight: number;
    remainingWeight: number;
    transmissionType: TransmissionTypeEnum;
    width: number;
    height: number;
    length: number;
    attributes: AdditionalAttributes[];
    anotherInnerAttributes?: string;
    anotherOuterAttributes?: string;
}

export interface LabelInDTO {
    name: string;
    color: string;
}

export interface ApplicationIDInDTO {
    year: number;
    applicationIdType: ApplicationIdTypeEnum;
}

export interface ApplicationIDOutDTO {
    applicationId: string;
}

export interface AddOrRemoveGuestInDTO {
    resident: boolean;
    title: string;
    firstName: string;
    lastName: string;
    category: CategoryEnum;
    dateOfBirth?: string;
    idType: IdTypeEnum;
    idNumber: string;
    streetNr: string;
    city: string;
    zipCode: string;
    countryCodeISO3: string;
}

export interface AddOrRemoveGuestsInDTO {
    guests: AddOrRemoveGuestInDTO[];
}

export interface ChangeRoomInDTO {
    resourceContractId: number;
    oldResourceUUID: string;
    newResourceUUID: string;
    preservePrice: boolean;
}

export interface ComputePriceOutDTO {
    totalPrice: number;
    since: string;
    till: string;
    prices: PriceElementOutDTO[];
    serviceFee: number;
}

export interface PriceElementOutDTO {
    name: string;
    amount: number;
    price: number;
}

export interface GuestInfoOutDTO {
    contractSince: string;
    contractTill: string;
    resourceName: string;
    totalGuests: number;
    guestEntryFinishedAt?: string;
    registeredGuests: GuestEntryOutDTO[];
}

export interface RoomDetailOutDTO {
    resourceUUID: string;
    name: string;
    numberOfBeds: number;
    places: number;
    area: number;
    bedType: BedTypeEnum;
    showerType: ShowerTypeEnum;
}

export interface PensionRoomListOutDTO {
    name: string;
    resourceGroupType: ResourceGroupTypeEnum;
    resourceGroupId: string;
    numberOfBeds: number;
    places: number;
    basicPrice: number;
    numberOfBedsAbsoluteSale?: string;
    area: number;
    bedType: BedTypeEnum;
    showerType: ShowerTypeEnum;
    prices?: PensionRoomListPriceOutDTO[];
    price?: number;
    mainImage?: string;
}

export interface PensionRoomListPriceOutDTO {
    guestCount: number;
    price: number;
}

export interface DayInfo {
    date: string;
    price: number;
    occupied: boolean;
}

export interface DisabledDayDTO {
    disabledDate: string;
}

export interface UserInfoOutDTO {
    loggedUser: string;
    companies: WorkspacesOutDTO[];
}

export interface WorkspacesOutDTO {
    workspaceName: string;
    uuid: string;
    roles: string[];
}

export interface SupplierInvoiceListOutDTO {
    applicationID: string;
    id: number;
    supplierName: string;
    dueDate?: string;
    originalNumber: string;
    orderId?: string;
    deliveryId?: string;
    total: number;
    subtotal: number;
    variableSymbol?: string;
    paidAt?: string;
    dueDays?: number;
    paidAmount: number;
    dueAmount: number;
    invoiceApplicationId?: string;
    invoicePaidAt?: string;
}

export interface SupplierInvoicePaymentInfoOutDTO {
    bankAccountWithCode: string;
    amount: number;
    vs?: string;
    ss?: string;
    ks?: string;
    description: string;
}

export interface RoomServiceBooking {
    reservedSince: string;
    reservedTill: string;
    guests: number;
}

export interface RoomServiceOverviewOutDTO {
    resourceUUID: string;
    name: string;
    internalName: string;
    dirty: boolean;
    critical: boolean;
    upcomingReservation?: RoomServiceBooking;
}

export interface RestListMenuItemFilter {
    categoryId?: number;
}

export interface Pageable {
    offset?: number;
    sort?: Sort;
    paged?: boolean;
    unpaged?: boolean;
    pageNumber?: number;
    pageSize?: number;
}

export interface Sort {
    unsorted?: boolean;
    sorted?: boolean;
    empty?: boolean;
}

export interface LovWithID {
    id: number;
    name: string;
}

export interface MenuItemOutDTO {
    id: number;
    name: string;
    altName?: string;
    imageURI?: string;
    price: number;
    description?: string;
    altDescription?: string;
    category?: LovWithID;
    menuItemVATGroup?: MenuItemVATGroupEnum;
    visible: boolean;
    amount?: number;
    unit?: string;
}

export interface PageResponseMenuItemOutDTO {
    data: MenuItemOutDTO[];
    total: number;
    page: number;
    size: number;
}

export interface Api2PdfResponse {
    file?: string;
    error?: string;
    seconds?: string;
    responseId?: string;
    success?: boolean;
    mbOut?: string;
    cost?: string;
    ResponseId?: string;
    Error?: string;
    MbOut?: string;
    FileUrl?: string;
    Success?: boolean;
    Cost?: string;
    Seconds?: string;
}

export interface AccommodationPriceInfo {
    contractID: number;
    contractName: string;
    month: number;
    guests: number;
    workingGuests: number;
    vacationingGuests: number;
    sincePayingPeriod: string;
    tillPayingPeriod: string;
    days: number;
    contractSince: string;
    contractTill: string;
    rooms: number;
    municipalTax: number;
}

export interface ResourceContractBooking {
    resourceName: string;
    resourceUUID: string;
    totalPrice: number;
    guestsCount: number;
}

export interface ResourceContractGuestOutDTO {
    resident: boolean;
    title: string;
    firstName: string;
    lastName: string;
    category: CategoryEnum;
    dateOfBirth?: string;
    idType: IdTypeEnum;
    idNumber: string;
    streetNr: string;
    city: string;
    zipCode: string;
    countryCodeISO3: string;
}

export interface ResourceContractInvoiceOutDTO {
    id: number;
    applicationId: string;
    totalPrice: number;
    subTotal: number;
    invoiceType: InvoiceTypeEnum;
    createdAt: string;
    dueDate: string;
    taxableAt: string;
    paidAt?: string;
}

export interface ResourceContractOutDTO {
    id: number;
    fullName: string;
    email?: string;
    phone?: string;
    contractSince: string;
    contractTill: string;
    contractedDaysCount: number;
    contractTotalPrice: number;
    overriddenTotalPrice?: number;
    totalGuestCount: number;
    paid: boolean;
    markedPaidAt?: string;
    checkoutDone: boolean;
    checkoutDoneAt?: string;
    checkinDone: boolean;
    checkinDoneAt?: string;
    resourceContractState: ResourceContractStateEnum;
    passivated: boolean;
    invoices: ResourceContractInvoiceOutDTO[];
    bookings: ResourceContractBooking[];
    guests: ResourceContractGuestOutDTO[];
    guestResourceKey: string;
}

export interface CheckinResourceOutDTO {
    contractId: number;
    name: string;
    totalPrice: number;
    guestsCount: number;
    paid: boolean;
    resourceNames: string[];
    contractSince: string;
    contractTill: string;
    checkinDone: boolean;
    dayCount: number;
    guestEntryFinishedAt?: string;
    totalGuestCount: number;
    prePaid: number;
    invoiced: boolean;
    phone: string;
    plannedArrival?: string;
}

export interface CheckoutResourceOutDTO {
    contractId: number;
    name: string;
    totalPrice: number;
    guestsCount: number;
    paid: boolean;
    checkoutDone: boolean;
    resourceNames: string[];
    contractSince: string;
    contractTill: string;
    dayCount: number;
    guestEntryFinishedAt?: string;
    totalGuestCount: number;
    prePaid: number;
}

export interface ReceptionOverviewOutDTO {
    checkinResources: CheckinResourceOutDTO[];
    checkoutResources: CheckoutResourceOutDTO[];
}

export interface LovBankAccountOutDTO {
    accountNumber: string;
}

export interface LoVWithUUID {
    uuid: string;
    name: string;
}

export interface ListReservationByResourceResourceOccupation {
    date: string;
    reservationId: number;
    resourceName: string;
    reservationName: string;
    reservationTotal: number;
}

export interface ListContractFilterDTO {
    resourceGroup?: string;
    resourceUUIDs?: string[];
    since?: string;
    till?: string;
}

export interface ContractListOutDTO {
    contractId: number;
    fullName: string;
    phone?: string;
    email?: string;
    contractSince: string;
    contractTill: string;
    totalPrice: number;
    overriddenPrice?: number;
    resourceName: string;
    bookedResources: ListContractBookedResourceOutDTO[];
    daysCount: number;
    guestCount: number;
    passivated: boolean;
    invoices: ListContractInvoiceOutDTO[];
    recordedInBook: boolean;
    paidAt?: string;
    createdAt?: string;
    municipalTax: number;
}

export interface ListContractBookedResourceOutDTO {
    resourceName: string;
    totalPrice: number;
    pricePerUnit: number;
    daysCount: number;
}

export interface ListContractInvoiceOutDTO {
    applicationId: string;
    total: number;
}

export interface ListBankItemsSynchronizedAccount {
    account: string;
    id: number;
}

export interface BankAccountListOutDTO {
    accounts: BankAccountOutDTO[];
}

export interface BankAccountOutDTO {
    name: string;
    accountId: string;
    bankId: string;
    bankCompany: BankCompanyEnum;
    lastSynchronized: string;
}

export interface InvoicePaymentInfoOutDTO {
    invoiceId: number;
    bankAccountWithCode: string;
    amount: number;
    vs?: string;
    ss?: string;
    ks?: string;
    description: string;
}

export interface InvoiceListOutDTO {
    exportedAt?: string;
    invoiceId: number;
    applicationId: string;
    externalId?: string;
    total: number;
    paid: boolean;
    customerName: string;
    variableSymbol?: string;
    createdAt: string;
    dueDate: string;
    taxableAt: string;
    payment: PaymentOutDTO[];
    dueAmount: number;
    attachmentUUID?: string;
    originalApplicationId?: string;
    sentAt?: string;
    resourceContract?: InvoiceListResourceContractOutDTO;
    invoiceType: InvoiceTypeEnum;
    paymentType: PaymentTypeEnum;
}

export interface InvoiceListResourceContractOutDTO {
    id: number;
    fullName: string;
}

export interface PaymentOutDTO {
    paidPercentage: number;
    paidAmount: number;
}

export interface BasicPriceOutDTO {
    id: number;
    since: string;
    till?: string;
    price: number;
}

export interface Company {
    id?: number;
    name?: string;
    streetNr?: string;
    city?: string;
    zipCode?: string;
    vat?: string;
    tin?: string;
    country?: string;
    vatPayer?: VatPayerEnum;
    phone?: string;
    url?: string;
    workspace?: Workspace;
    additionalTextIssuedInvoice?: string;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ExceptionPriceOutDTO {
    id: number;
    since: string;
    till?: string;
    price: number;
}

export interface GetRentalDetailOutDTO {
    rentalName: string;
    rentalEnabled: boolean;
    basicPrices?: BasicPriceOutDTO[];
    exceptionPrices?: ExceptionPriceOutDTO[];
    sales?: SalesOutDTO[];
    deposit?: number;
    serviceFee?: number;
    cancelationConditions?: ResourceCancellationCondition[];
    paymentCondition?: PaymentCondition[];
    driverLicence?: string;
    minimalDays?: number;
    minimalAge?: number;
    maximumDistancePerDay?: number;
    additionalPricePerKm?: number;
    additionalConditions?: string;
}

export interface LocalTime {
    hour?: number;
    minute?: number;
    second?: number;
    nano?: number;
}

export interface Module {
    id?: number;
    moduleName?: string;
    url?: string;
    urlMode?: UrlModeEnum;
    workspace?: Workspace;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface PaymentCondition {
    id?: number;
    paymentDueDays?: number;
    paymentInPercentage?: number;
    resource?: Resource;
}

export interface Resource {
    id?: number;
    uuid?: string;
    name?: string;
    internalName?: string;
    basicPricePerGuest?: boolean;
    basicPrices?: ResourceBasicPrice[];
    exceptionPrices?: ResourceExceptionPrice[];
    sales?: ResourceSale[];
    checkIn?: LocalTime;
    checkOut?: LocalTime;
    minimalDays?: number;
    reservationCalculationMode?: ReservationCalculationModeEnum;
    coolDown?: number;
    howManyDaysFromToday?: number;
    numberOfGuestAbsoluteSale?: string;
    deposit?: number;
    serviceFee?: number;
    cancellationConditions?: ResourceCancellationCondition[];
    paymentConditions?: PaymentCondition[];
    workspace?: Workspace;
    prepared?: boolean;
    dirtyAt?: string;
    cleanedAt?: string;
    maxGuests?: number;
    pensionRoom?: ResourcePensionRoom;
    car?: ResourceCar;
    mainImage?: string;
    resourceGroup?: ResourceGroup;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceBasicPrice {
    id?: number;
    since?: string;
    till?: string;
    price?: number;
    resource?: Resource;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceCancellationCondition {
    id?: number;
    daysBeforeContract?: number;
    discount?: number;
    resource?: Resource;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceCar {
    id?: number;
    name?: string;
    internalName?: string;
    resourceCarType?: ResourceCarTypeEnum;
    carDescription?: string;
    stkTill?: string;
    emissionTill?: string;
    manofacturer?: string;
    maximalSpeed?: number;
    maxPersonRide?: number;
    maxPersonSleep?: number;
    fuelType?: FuelTypeEnum;
    engineVolume?: number;
    enginePower?: number;
    yearOfProduction?: number;
    maximalWeight?: number;
    plainWeight?: number;
    remainingWeight?: number;
    transmissionType?: TransmissionTypeEnum;
    width?: number;
    height?: number;
    length?: number;
    attributes?: AdditionalAttributes[];
    anotherInnerAttributes?: string;
    anotherOuterAttributes?: string;
    workspace?: Workspace;
    locality?: string;
    driverLicence?: string;
    minimalDays?: number;
    minimalAge?: number;
    maximumDistancePerDay?: number;
    additionalPricePerKm?: number;
    additionalConditions?: string;
    resource?: Resource;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceExceptionPrice {
    id?: number;
    since?: string;
    till?: string;
    price?: number;
    resource?: Resource;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceGroup {
    id?: number;
    uuid?: string;
    name?: string;
    resources?: Resource[];
    resourceGroupType?: ResourceGroupTypeEnum;
    defaultVAT?: number;
    closedForGuestsRegistration?: boolean;
    workspace?: Workspace;
}

export interface ResourcePensionRoom {
    id?: number;
    places?: number;
    numberOfBeds?: number;
    area?: number;
    bedType?: BedTypeEnum;
    showerType?: ShowerTypeEnum;
    resource?: Resource;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface ResourceSale {
    id?: number;
    minimalDays?: number;
    percentage?: number;
    resource?: Resource;
}

export interface SalesOutDTO {
    id: number;
    minimalDays: number;
    percentage: number;
}

export interface Workspace {
    uuid?: string;
    workspaceName?: string;
    modules?: Module[];
    company?: Company;
    preferred?: boolean;
    get_createdAt?: string;
    get_updatedAt?: string;
    get_version?: number;
}

export interface LabelOutDTO {
    id: number;
    name: string;
    color: string;
}

export interface GetMenuItemCategoriesOutDTO {
    id: number;
    name: string;
}

export interface CompanyOutDTO {
    city: string;
    companyName: string;
    country: string;
    streetNr: string;
    tin: string;
    vat?: string;
    zipCode: string;
    vatPayer: VatPayerEnum;
    phone?: string;
    url?: string;
    additionalTextIssuedInvoice?: string;
}

export interface ContactOutDTO {
    id: number;
    city: string;
    companyName: string;
    country: string;
    streetNr: string;
    tin: string;
    vat?: string;
    zipCode: string;
}

export interface GetInvoiceOutDTO {
    id: number;
    issuer: CompanyOutDTO;
    customer: ContactOutDTO;
    applicationId: string;
    invoiceType: InvoiceTypeEnum;
    dueDate: string;
    createdAt: string;
    taxableAt: string;
    variableSymbol: string;
    specificSymbol?: string;
    constantSymbol?: string;
    rows: InvoiceLineOutDTO[];
    accountNumber: string;
    paymentType: string;
    vatComputeMode: VatComputeModeEnum;
    transferredTaxLiability: boolean;
    currencyCode: string;
    paymentQRCodeURL: string;
    vatSummary: VATSummary[];
    total: number;
    invoiceNote?: string;
}

export interface InvoiceLineOutDTO {
    amount: number;
    unit: string;
    description: string;
    pricePerUnit: number;
    vatPercentage: number;
    totalPerLine: number;
    subTotalPerLine: number;
    totalVat: number;
}

export interface VATSummary {
    vatPercentage: number;
    withoutVat: number;
    totalPerVat: number;
}

export interface ExpenseDetailOutDTO {
    applicationId: string;
    createdAt: string;
    dueDate: string;
    taxableAt: string;
    variableSymbol: string;
    constantSymbol?: string;
    specificSymbol?: string;
    accountNumber: string;
    vatComputeMode: VatComputeModeEnum;
    rows: ExpenseDetailRowOutDTO[];
    transferredTaxLiability: boolean;
}

export interface ExpenseDetailRowOutDTO {
    amount: number;
    unit: string;
    description: string;
    pricePerUnit: number;
    vatPercentage: number;
}

export interface GetCompanyRegistrationOutDTO {
    city: string;
    companyName: string;
    country: string;
    streetNr: string;
    tin: string;
    vat?: string;
    zipCode: string;
    vatPayer: VatPayerEnum;
    phone?: string;
    url?: string;
}

export interface Attachment {
    content_type: string;
    download_url: string;
    file_name: string;
}

export interface Expense {
    attachment?: Attachment;
    bank_account?: string;
    created_at: string;
    currency: string;
    description?: string;
    document_type: string;
    due_on?: string;
    exchange_rate: number;
    html_url: string;
    iban?: string;
    id: number;
    issued_on: string;
    lines: Line[];
    native_subtotal: number;
    native_total: number;
    number: string;
    original_number?: string;
    paid_amount: number;
    paid_on?: string;
    payment_method: string;
    private_note?: string;
    status: string;
    subject_id: number;
    subject_url: string;
    subtotal: number;
    supplier_city: string;
    supplier_country: string;
    supplier_name: string;
    supplier_registration_no: string;
    supplier_street: string;
    supplier_vat_no: string;
    supplier_zip: string;
    supply_code?: number;
    swift_bic?: any;
    taxable_fulfillment_due: string;
    total: number;
    transferred_tax_liability: boolean;
    updated_at: string;
    url: string;
    variable_symbol?: string;
    vat_price_mode: string;
}

export interface DashboardOutDTO {
    bankItemRatio: number;
    notPaidExpensesTotal: number;
    notPaidInvoicesTotal: number;
    bankItemNotPaidCount: number;
    bankItemTotalCount: number;
}

export interface DomesticTransaction {
    accountFrom: string;
    currency: string;
    amount: number;
    accountTo: string;
    bankCode: string;
    ks: string;
    vs: string;
    ss: string;
    date: string;
    messageForRecipient: string;
    comment: string;
    paymentType: string;
}

export interface LovWithAccount {
    id: number;
    name: string;
    accountNumber?: string;
}

export interface CarListOutDTO {
    carId: number;
    internalName: string;
    carType: CarTypeEnum;
    stkTill: string;
    emissionTill: string;
}

export interface CarDetailOutDTO {
    id: number;
    name: string;
    internalName?: string;
    carType: CarTypeEnum;
    carDescription: string;
    stkTill?: string;
    emissionTill?: string;
    manofacturer: string;
    locality?: string;
    maximalSpeed: number;
    maxPersonRide: number;
    maxPersonSleep: number;
    fuelType: FuelTypeEnum;
    engineVolume: number;
    enginePower: number;
    yearOfProduction: number;
    maximalWeight: number;
    plainWeight: number;
    remainingWeight: number;
    transmissionType: TransmissionTypeEnum;
    width: number;
    height: number;
    length: number;
    attributes: AdditionalAttributes[];
    anotherInnerAttributes?: string;
    anotherOuterAttributes?: string;
}

export interface BorrowedCarsListOutDTO {
    borrowedObjectName: string;
    actualPrice?: number;
    carId: number;
}

export interface AttachmentListOutDTO {
    fileName: string;
    size: number;
    uuid: string;
}

export interface LoggedUserServiceOutDTO {
    resourceGroupName: string;
    resourceGroupId: string;
    userName: string;
    userEmail: string;
}

export interface GetTablesListDTO {
    tableName: string;
    id: number;
    hasOrder: boolean;
}

export interface RstOrderItemOutDTO {
    orderItemid: number;
    menuItemId: number;
    name: string;
    price: number;
    vat: number;
    quantity: number;
    totalPrice: number;
}

export interface GetMenuItemsPerCategoryOutDTO {
    menuItemId: number;
    name: string;
    price: number;
    vat: number;
    description?: string;
    category?: string;
}

export interface GetCategoriesOutDTO {
    categoryId: number;
    name: string;
}

export interface LoVCodeOnly {
    code: string;
}

export interface Authentication {
    authenticated?: boolean;
    details?: any;
    principal?: any;
    authorities?: GrantedAuthority[];
    credentials?: any;
    name?: string;
}

export interface GrantedAuthority {
    authority?: string;
}

export interface UserLoginInfoOutDTO {
    stage: StageEnum;
    workspaces?: UserLoginInfoWorkspace[];
}

export interface UserLoginInfoWorkspace {
    uuid: string;
    workspaceName: string;
    isPrefered: boolean;
}

export interface GetIdentityOutDTO {
    workspaces?: GetIdentityWorkspace[];
}

export interface GetIdentityWorkspace {
    uuid: string;
    workspaceName: string;
    prefered: boolean;
}

export interface AresResponse {
    ico?: string;
    dic?: string;
    name?: string;
    street?: string;
    city?: string;
    zipCode?: string;
    streetNumber1?: string;
    streetNumber2?: string;
}

export enum ScopeEnum { FIELD='FIELD',COMPONENT='COMPONENT',GLOBAL='GLOBAL' }
export enum ErrorTypeEnum { ERROR='ERROR',VALIDATION='VALIDATION',CONFLICT='CONFLICT' }
export enum DocumentTypeEnum { EXPENSE='EXPENSE',INVOICE='INVOICE' }
export enum ObjectTypeEnum { INVOICE='INVOICE',EXPENSE='EXPENSE' }
export enum DirectionEnum { PAY_EXPENSE='PAY_EXPENSE',PAY_INVOICE='PAY_INVOICE' }
export enum TypeEnum { IN_BANK_IN='IN_BANK_IN',IN_BANK_OUT='IN_BANK_OUT',CASHIER_IN='CASHIER_IN',CASHIER_OUT='CASHIER_OUT',CASH_IN='CASH_IN',CASH_OUT='CASH_OUT',PAYMENT='PAYMENT',INCOME='INCOME',CASHLESS_PAYMENT='CASHLESS_PAYMENT',CASHLESS_INCOME='CASHLESS_INCOME',CARD_PAYMENT='CARD_PAYMENT',LOAN_INTEREST='LOAN_INTEREST',PENALTY_FEE='PENALTY_FEE',DELIVERY_OUT='DELIVERY_OUT',DELIVERY_INCOME='DELIVERY_INCOME',IN_ACCOUNT='IN_ACCOUNT',ACCOUNTED_INTEREST='ACCOUNTED_INTEREST',ACCOUNTED_PAYED_INTEREST='ACCOUNTED_PAYED_INTEREST',INTEREST_TAX='INTEREST_TAX',LISTED_INTEREST='LISTED_INTEREST',FEE='FEE',LISTED_FEE='LISTED_FEE',OWN_ACCOUNTS_OUT='OWN_ACCOUNTS_OUT',OWN_ACCOUNTS_IN='OWN_ACCOUNTS_IN',UNKNOWN_OUT='UNKNOWN_OUT',UNKNOWN_IN='UNKNOWN_IN',OWN_TRANSFER_OUT='OWN_TRANSFER_OUT',OWN_TRANSFER_IN='OWN_TRANSFER_IN',OWN_CASHIER_OUT='OWN_CASHIER_OUT',OWN_CASHIER_IN='OWN_CASHIER_IN',PAYMENT_FIX='PAYMENT_FIX',INCOME_FEE='INCOME_FEE',FX_PAYMENT='FX_PAYMENT',CARD_FEE='CARD_FEE',DIRECT_DEBIT='DIRECT_DEBIT',DIRECT_DEBIT_ACCOUNT='DIRECT_DEBIT_ACCOUNT',DIRECT_DEBIT_OUT='DIRECT_DEBIT_OUT',DIRECT_DEBIT_IN='DIRECT_DEBIT_IN',INSTANT_IN='INSTANT_IN',INSTANT_OUT='INSTANT_OUT' }
export enum ApplicationIdTypeEnum { SUPPLIER_INVOICE='SUPPLIER_INVOICE',ISSUED_INVOICE='ISSUED_INVOICE',ISSUED_PROFORMA='ISSUED_PROFORMA',RECEIVED_PAYMENT_INVOICE='RECEIVED_PAYMENT_INVOICE',EU_INVOICE='EU_INVOICE' }
export enum MenuItemVATGroupEnum { BEV_REGULAR='BEV_REGULAR',BEV_BEER='BEV_BEER',BEV_NONALCOHOLIC_BEER='BEV_NONALCOHOLIC_BEER',FOOD_REGULAR='FOOD_REGULAR' }
export enum CategoryEnum { ADULT='ADULT',CHILD='CHILD',ZTP='ZTP',OVER60='OVER60',WORK='WORK' }
export enum IdTypeEnum { OP='OP',PAS='PAS',PPPU='PPPU',KARTAEU='KARTAEU',PPP='PPP',PPPPC='PPPPC',PPTP='PPTP',PZUMO='PZUMO',PZPDO='PZPDO' }
export enum ResourceContractStateEnum { VALID='VALID',CANCELED='CANCELED' }
export enum BankCompanyEnum { FIO='FIO',CSOB='CSOB' }
export enum PaymentTypeEnum { bank='bank',cash='cash',code='code',paypal='paypal',card='card' }
export enum UrlModeEnum { WORKSPACE='WORKSPACE',GENERAL='GENERAL' }
export enum ReservationCalculationModeEnum { PER_DAY='PER_DAY',PER_NIGHT='PER_NIGHT' }
export enum ResourceCarTypeEnum { MOTORHOME='MOTORHOME',CARAVAN='CARAVAN' }
export enum ResourceGroupTypeEnum { PENSIONROOM='PENSIONROOM',MOTORHOME='MOTORHOME',RESTAURANT='RESTAURANT' }
export enum BedTypeEnum { TWINBED='TWINBED',SINGLEBED='SINGLEBED' }
export enum ShowerTypeEnum { BATH='BATH',SHOWER='SHOWER' }
export enum InvoiceTypeEnum { REGULAR_INVOICE='REGULAR_INVOICE',PROFORMA='PROFORMA',RECEIVED_PAYMENT_INVOICE='RECEIVED_PAYMENT_INVOICE',EU_INVOICE='EU_INVOICE' }
export enum VatComputeModeEnum { FROM_BASE='FROM_BASE',FROM_TOTAL='FROM_TOTAL' }
export enum VatPayerEnum { NO='NO',PAYER='PAYER',IDENTIFIED_PERSON='IDENTIFIED_PERSON' }
export enum CarTypeEnum { MOTORHOME='MOTORHOME',CARAVAN='CARAVAN' }
export enum FuelTypeEnum { DIESEL='DIESEL',PETROL='PETROL' }
export enum TransmissionTypeEnum { MANUAL='MANUAL',AUTOMAT='AUTOMAT' }
export enum StageEnum { NOT_LOGGED='NOT_LOGGED',NO_IDENTITY='NO_IDENTITY',FULL_IDENTITY='FULL_IDENTITY' } 


 

export interface FrontendServiceType {
    sendDocumentToPCloud: (body: StoreToPCloudInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    applyLabels: (body: SetLabelsInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    sendInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    markResourceAsCleaned: (resourceUUID: string, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstChangeMenuItemVisibility: (resourceGroupUUID: string,body: RestMenuItemChangeVisibilityInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    markCheckoutDone: (contractId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    markCheckinDone: (contractId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    markAsPaid: (contractId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    pairInvoiceToContract: (body: PairInvoiceToContractInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    updateInvoice: (body: UpdateInvoiceInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createInvoice: (body: InvoiceInDTO, onSuccess: ((data : CreateInvoiceOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovContacts: (onSuccess: ((data : LovWithID[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createContact: (body: ContactControllerInDTO, onSuccess: ((data : ContactControllerOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    changeCompanyRegistration: (body: ChangeCompanyInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listAttachments: (onSuccess: ((data : AttachmentListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createAttachment: (body: AttachmentInDTO, onSuccess: ((data : AttachmentOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    updateUserFullName: (body: FullNameInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstRemoveOrderItemFromTable: (tableId: number,itemId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstPayOrderItems: (tableId: number,body: PartialPay, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstMoveOrderItemsToTable: (tableIdFrom: number,tableIdTo: number,body: OrderItems, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstModifyOrderItemCount: (tableId: number,itemId: number,count: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstComputePayment: (tableId: number,body: PartialPay, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstAddOrderItemToTable: (tableId: number,menuItemId: number,amount: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    addNewIdentity: (body: IdentityInDTO, onSuccess: ((data : IdentityOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createWorkspace: (body: WorkspaceNameInDTO, onSuccess: ((data : AddWorkspaceOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    generateDownloadLink: (body: DownloadAttachmentGenerateLinkInDTO, onSuccess: ((data : any)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getGuestInfo: (guestResourceKey: string, onSuccess: ((data : GuestInfoOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    updateGuestInfo: (guestResourceKey: string,body: GuestInfoInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createResourceReservation: (body: ReservationInDTO, onSuccess: ((data : number)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    checkReservation: (body: CheckReservationInDTO, onSuccess: ((data : CheckReservationOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    updateDurationAndGuestCount: (reservationId: number,body: UpdateDurationAndGuestsInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createSupplierInvoice: (body: SupplierInvoiceInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    sendAccommodationDetailReminder: (reservationId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    restCreateOrUpdateMenuItem: (resourceGroupUUID: string,body: RestCreateUpdateMenuItemIn, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listPriceItems: (onSuccess: ((data : PriceItemOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createPriceListItem: (body: PriceItemInDTO, onSuccess: ((data : PriceItemOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    findPayment: (body: PaymentFindInDTO, onSuccess: ((data : BankItemOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    passivateContract: (contractId: number,body: PassivateContractInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadBankItems: (body: ListBankItemsFilterInDTO, onSuccess: ((data : ListBankItemsOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    linkInvoiceWithBankItem: (body: InvoicePaymentLinkInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    linkSupplierInvoiceWithBankItem: (body: ExpensePaymentLinkInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getGalleryData: (body: GetGalleryDataInDTO, onSuccess: ((data : GetGalleryDataOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    generateInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    computeFacturationSummary: (body: ComputationSummaryInDTO, onSuccess: ((data : FacturationSummeryOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    adminCreateResourceReservation: (body: ReservationInDTO, onSuccess: ((data : number)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createOrUpdateCar: (body: CreateOrUpdateCarInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    createLabel: (body: LabelInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    cancelReservation: (reservationId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getNextApplicationId: (body: ApplicationIDInDTO, onSuccess: ((data : ApplicationIDOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    addOrReplaceGuests: (resourceContractId: number,body: AddOrRemoveGuestsInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstFinishOrder: (tableId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    changeReservationRoom: (body: ChangeRoomInDTO, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getDownloadDocumentForExpedition: (attachmentUUID: string, onSuccess: ((data : any)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    computePrice: (resourceId: string,sinceInLocalDate: string,tillInLocalDate: string, onSuccess: ((data : ComputePriceOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadResourceDetails: (listOfResourceUUUID: string[], onSuccess: ((data : RoomDetailOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listResourceGroup: (resourceGroupId: string,resourceGroupType: string, onSuccess: ((data : PensionRoomListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    get: (onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getResourceCalendarInfo: (resourceUUID: string,since: string,till: string, onSuccess: ((data : DayInfo[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getDisabledDaysForResourceGroup: (resourceGroup: string,since: string,till: string, onSuccess: ((data : DisabledDayDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getUserInfo: (onSuccess: ((data : UserInfoOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listSupplierInvoices: (onSuccess: ((data : SupplierInvoiceListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadSupplierInvoicePaymentInfo: (supplierInvoiceId: number, onSuccess: ((data : SupplierInvoicePaymentInfoOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getRoomServiceOverview: (resourceGroupUUID: string, onSuccess: ((data : RoomServiceOverviewOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    restListMenuItems: (resourceGroupUUID: string,filter: RestListMenuItemFilter,page: Pageable, onSuccess: ((data : PageResponseMenuItemOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    generateResourceReportPDF: (year: number,quarter: number,resourceGroupUUID: string, onSuccess: ((data : Api2PdfResponse)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    computeResourceReport: (year: number,quarter: number,resourceGroupUUID: string, onSuccess: ((data : AccommodationPriceInfo[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getReservationDetail: (reservationid: number, onSuccess: ((data : ResourceContractOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadReceptionOverview: (effectiveDate: string, onSuccess: ((data : ReceptionOverviewOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadPriceListItemByCode: (code: string, onSuccess: ((data : PriceItemOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    tryToLinkPayments: (onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovBankAccounts: (onSuccess: ((data : LovBankAccountOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovListResources: (onSuccess: ((data : LoVWithUUID[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovListResourceGroups: (onSuccess: ((data : LoVWithUUID[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listReservationsByResource: (resourceGroupUUID: string,sinceLocalDate: string,tillLocalDate: string, onSuccess: ((data : ListReservationByResourceResourceOccupation[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listContracts: (filterDTO: ListContractFilterDTO, onSuccess: ((data : ContractListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovSynchronizedAccounts: (onSuccess: ((data : ListBankItemsSynchronizedAccount[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadBankAccounts: (onSuccess: ((data : BankAccountListOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadInvoicePaymentInfo: (invoiceId: number, onSuccess: ((data : InvoicePaymentInfoOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listInvoices: (onSuccess: ((data : InvoiceListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadRentalDetail: (carId: number, onSuccess: ((data : GetRentalDetailOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadObjectLabels: (objectType: string,objectId: number, onSuccess: ((data : LabelOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetCategories: (resourceGroupUUID: string, onSuccess: ((data : GetMenuItemCategoriesOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadLabels: (onSuccess: ((data : LabelOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadInvoiceDetail: (invoiceId: number, onSuccess: ((data : GetInvoiceOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadExpenseById: (expenseId: number, onSuccess: ((data : ExpenseDetailOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getCompanyRegistration: (onSuccess: ((data : GetCompanyRegistrationOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    list: (page: number, onSuccess: ((data : Expense[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    synchronizeInvoices: (c: Company, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    synchonizeExpesesFromFakturoid: (c: Company, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getDashboardData: (onSuccess: ((data : DashboardOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    paySupplierInvoice: (supplierInvoiceId: number, onSuccess: ((data : DomesticTransaction)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovContactsWithAccount: (onSuccess: ((data : LovWithAccount[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    listCars: (onSuccess: ((data : CarListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadCarDetail: (carId: number, onSuccess: ((data : CarDetailOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    loadBorrowedCars: (onSuccess: ((data : BorrowedCarsListOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetLoggedUserInfo: (onSuccess: ((data : LoggedUserServiceOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetTableList: (onSuccess: ((data : GetTablesListDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetOrderItems: (tableId: number, onSuccess: ((data : RstOrderItemOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetMenuItems: (onSuccess: ((data : GetMenuItemsPerCategoryOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetMenuItemsPerCategory: (categoryId: number, onSuccess: ((data : GetMenuItemsPerCategoryOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstGetCategories_1: (onSuccess: ((data : GetCategoriesOutDTO[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    lovBankItemType: (onSuccess: ((data : LoVCodeOnly[])=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getInfo: (onSuccess: ((data : Authentication)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    hasUserIdentity: (onSuccess: ((data : UserLoginInfoOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getUserIdentity: (onSuccess: ((data : GetIdentityOutDTO)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    getCompanyFromAresByICO: (ico: string, onSuccess: ((data : AresResponse)=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    deletePriceListItem: (id: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    deleteResourceContract: (resourceContractId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    deleteInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    deleteExpense: (expenseId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    deleteAttachment: (uuid: string, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
    rstDeleteOrder: (tableId: number, onSuccess: (()=>void), hashAuthentication?: string, callbacks?: Callbacks) => void;
}

const resolveQueryParams = (params: any[]) => {
    const retObject = {};
    params.forEach(item=>{
      Object.keys(item).forEach(key=>{
         retObject[key] = item[key];
      });
    });

    return retObject;
}

interface Error {
  message: string;
  name: string;
  stack: string;
  config: {
    url: string;
    method: string;
    data: string;
    headers: { [key: string]: string };
  };
}

interface Callbacks {
    onError?: (error: Error) => void;
    onConflict?: (conflictInfo: any) => void;
    onValidationFailed?: (originalResponse: any) => void;
    onEntityNotFound?: (originalResponse: any) => void;
    onAdditionalAction?: (additionalAction: any) => void;
}

const hasAdditionalAction = (response: AxiosResponse) => {
  if (response.status >= 200 && response.status < 300 && response.data.errorType === "ADDITIONAL_ACTION") {
    return true;
  }
  return false;
}

const isSuccess = (response: AxiosResponse) => {
return response.status >= 200 && response.status < 300 && response.data.errorType === null
}

const isConflict = (response: AxiosResponse) => {
if (response.status >= 200 && response.status < 300 && response.data.errorType === "CONFLICT") {
return true;
}
return false;
}

const entityNotFound = (response: AxiosResponse) => {
    if (response.status == 204) {
    return true;
    }
    return false;
}

const validationFailed = (response: AxiosResponse) => {
    if (response.status == 200 && response.data.errorType === "VALIDATION") {
    return true;
    }
    return false;
}

export const frontendServiceGenerator = (
backendUrl: string,
i18n: any,
handleError: (error: Error, payload?) => any,
getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<IdToken>,
    router : any,
    ): FrontendServiceType => {

  const apiMethods = {
        sendDocumentToPCloud: (body: StoreToPCloudInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/store-to-p-cloud, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/store-to-p-cloud`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/store-to-p-cloud, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/store-to-p-cloud`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        applyLabels: (body: SetLabelsInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/set-labels, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/set-labels`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/set-labels, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/set-labels`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        sendInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/send-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/send-invoice/${invoiceId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/send-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/send-invoice/${invoiceId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        markResourceAsCleaned: (resourceUUID: string, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/roomservice-overview/${resourceUUID}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/roomservice-overview/${resourceUUID}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/roomservice-overview/${resourceUUID}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/roomservice-overview/${resourceUUID}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstChangeMenuItemVisibility: (resourceGroupUUID: string,body: RestMenuItemChangeVisibilityInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}/change-visibility, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}/change-visibility`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}/change-visibility, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}/change-visibility`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        markCheckoutDone: (contractId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-checkout-done/${contractId}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/reception-overview/mark-checkout-done/${contractId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-checkout-done/${contractId}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/reception-overview/mark-checkout-done/${contractId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        markCheckinDone: (contractId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-checkin-done/${contractId}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/reception-overview/mark-checkin-done/${contractId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-checkin-done/${contractId}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/reception-overview/mark-checkin-done/${contractId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        markAsPaid: (contractId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-as-paid/${contractId}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/reception-overview/mark-as-paid/${contractId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/reception-overview/mark-as-paid/${contractId}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/reception-overview/mark-as-paid/${contractId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        pairInvoiceToContract: (body: PairInvoiceToContractInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/pair-invoice-to-contract, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/pair-invoice-to-contract`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/pair-invoice-to-contract, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/pair-invoice-to-contract`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        updateInvoice: (body: UpdateInvoiceInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/invoice, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/invoice`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/invoice, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/invoice`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        createInvoice: (body: InvoiceInDTO, onSuccess: ((data : CreateInvoiceOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/invoice, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/invoice`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/invoice, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/invoice`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        lovContacts: (onSuccess: ((data : LovWithID[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/contacts, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/contacts`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/contacts, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/contacts`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        createContact: (body: ContactControllerInDTO, onSuccess: ((data : ContactControllerOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/contacts, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/contacts`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/contacts, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/contacts`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        changeCompanyRegistration: (body: ChangeCompanyInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/change-company, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/change-company`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/change-company, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/change-company`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        listAttachments: (onSuccess: ((data : AttachmentListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/attachment, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/attachment`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/attachment, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/attachment`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        createAttachment: (body: AttachmentInDTO, onSuccess: ((data : AttachmentOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/attachment, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/${slugs}/attachment`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/${slugs}/attachment, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/${slugs}/attachment`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        updateUserFullName: (body: FullNameInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/save-name, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/save-name`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/save-name, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/save-name`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        rstRemoveOrderItemFromTable: (tableId: number,itemId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/remove-from-table/${tableId}/${itemId}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/remove-from-table/${tableId}/${itemId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/remove-from-table/${tableId}/${itemId}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/remove-from-table/${tableId}/${itemId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstPayOrderItems: (tableId: number,body: PartialPay, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/pay-order-items/${tableId}, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/pay-order-items/${tableId}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/pay-order-items/${tableId}, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/pay-order-items/${tableId}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        rstMoveOrderItemsToTable: (tableIdFrom: number,tableIdTo: number,body: OrderItems, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/move-order-item/${tableIdFrom}/${tableIdTo}/, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/move-order-item/${tableIdFrom}/${tableIdTo}/`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/move-order-item/${tableIdFrom}/${tableIdTo}/, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/move-order-item/${tableIdFrom}/${tableIdTo}/`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        rstModifyOrderItemCount: (tableId: number,itemId: number,count: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/modify-count/${tableId}/${itemId}/${count}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/modify-count/${tableId}/${itemId}/${count}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/modify-count/${tableId}/${itemId}/${count}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/modify-count/${tableId}/${itemId}/${count}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstComputePayment: (tableId: number,body: PartialPay, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/compute-payment/${tableId}, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/compute-payment/${tableId}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/compute-payment/${tableId}, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/compute-payment/${tableId}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        rstAddOrderItemToTable: (tableId: number,menuItemId: number,amount: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/add-to-table/${tableId}/${menuItemId}/${amount}, with data: ${JSON.stringify({})}`);
            axios
            .put(`${backendUrl}/api/rst-waiter/add-to-table/${tableId}/${menuItemId}/${amount}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/rst-waiter/add-to-table/${tableId}/${menuItemId}/${amount}, with data: ${JSON.stringify({})}`);
             
            axios
                .put(`${backendUrl}/api/rst-waiter/add-to-table/${tableId}/${menuItemId}/${amount}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        addNewIdentity: (body: IdentityInDTO, onSuccess: ((data : IdentityOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/identity/create, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/identity/create`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/identity/create, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/identity/create`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        createWorkspace: (body: WorkspaceNameInDTO, onSuccess: ((data : AddWorkspaceOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending put -> ${backendUrl}/api/add-workspace, with data: ${JSON.stringify({body})}`);
            axios
            .put(`${backendUrl}/api/add-workspace`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending put -> ${backendUrl}/api/add-workspace, with data: ${JSON.stringify({body})}`);
             
            axios
                .put(`${backendUrl}/api/add-workspace`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        generateDownloadLink: (body: DownloadAttachmentGenerateLinkInDTO, onSuccess: ((data : any)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/public/download-attachment, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/public/download-attachment`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/public/download-attachment, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/public/download-attachment`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        getGuestInfo: (guestResourceKey: string, onSuccess: ((data : GuestInfoOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/${guestResourceKey}/guest-info, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/${guestResourceKey}/guest-info`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        updateGuestInfo: (guestResourceKey: string,body: GuestInfoInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending post -> ${backendUrl}/public-api/${guestResourceKey}/guest-info, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/public-api/${guestResourceKey}/guest-info`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
        
        },
        createResourceReservation: (body: ReservationInDTO, onSuccess: ((data : number)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending post -> ${backendUrl}/public-api/create-reservation, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/public-api/create-reservation`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
        
        },
        checkReservation: (body: CheckReservationInDTO, onSuccess: ((data : CheckReservationOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending post -> ${backendUrl}/public-api/check-reservation, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/public-api/check-reservation`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
        
        },
        updateDurationAndGuestCount: (reservationId: number,body: UpdateDurationAndGuestsInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/update-duration-and-guests/${reservationId}, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/update-duration-and-guests/${reservationId}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/update-duration-and-guests/${reservationId}, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/update-duration-and-guests/${reservationId}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        createSupplierInvoice: (body: SupplierInvoiceInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/supplier-invoice, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/supplier-invoice`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/supplier-invoice, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/supplier-invoice`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        sendAccommodationDetailReminder: (reservationId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/send-accommodation-reminder/${reservationId}, with data: ${JSON.stringify({})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/send-accommodation-reminder/${reservationId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/send-accommodation-reminder/${reservationId}, with data: ${JSON.stringify({})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/send-accommodation-reminder/${reservationId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        restCreateOrUpdateMenuItem: (resourceGroupUUID: string,body: RestCreateUpdateMenuItemIn, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/rest-create-update-menu-item/${resourceGroupUUID}, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/rest-create-update-menu-item/${resourceGroupUUID}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/rest-create-update-menu-item/${resourceGroupUUID}, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/rest-create-update-menu-item/${resourceGroupUUID}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        listPriceItems: (onSuccess: ((data : PriceItemOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/pricelist, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/pricelist`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/pricelist, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/pricelist`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        createPriceListItem: (body: PriceItemInDTO, onSuccess: ((data : PriceItemOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/pricelist, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/pricelist`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/pricelist, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/pricelist`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        findPayment: (body: PaymentFindInDTO, onSuccess: ((data : BankItemOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/payment/find, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/payment/find`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/payment/find, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/payment/find`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        passivateContract: (contractId: number,body: PassivateContractInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/passivate-contract/${contractId}, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/passivate-contract/${contractId}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/passivate-contract/${contractId}, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/passivate-contract/${contractId}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        loadBankItems: (body: ListBankItemsFilterInDTO, onSuccess: ((data : ListBankItemsOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/list-bank-items, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/list-bank-items`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/list-bank-items, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/list-bank-items`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        linkInvoiceWithBankItem: (body: InvoicePaymentLinkInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/link-payment/invoice, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/link-payment/invoice`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/link-payment/invoice, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/link-payment/invoice`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        linkSupplierInvoiceWithBankItem: (body: ExpensePaymentLinkInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/link-payment/expense, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/link-payment/expense`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/link-payment/expense, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/link-payment/expense`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        getGalleryData: (body: GetGalleryDataInDTO, onSuccess: ((data : GetGalleryDataOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/get-gallery-data, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/get-gallery-data`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/get-gallery-data, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/get-gallery-data`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        generateInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/generate-invoice-pdf/${invoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/generate-invoice-pdf/${invoiceId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/generate-invoice-pdf/${invoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/generate-invoice-pdf/${invoiceId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        computeFacturationSummary: (body: ComputationSummaryInDTO, onSuccess: ((data : FacturationSummeryOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/facturation-summary, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/facturation-summary`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/facturation-summary, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/facturation-summary`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        adminCreateResourceReservation: (body: ReservationInDTO, onSuccess: ((data : number)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-reservation, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/create-reservation`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-reservation, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/create-reservation`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        createOrUpdateCar: (body: CreateOrUpdateCarInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-or-update-car, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/create-or-update-car`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-or-update-car, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/create-or-update-car`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        createLabel: (body: LabelInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-label, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/create-label`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/create-label, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/create-label`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        cancelReservation: (reservationId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/cancel-reservation/${reservationId}, with data: ${JSON.stringify({})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/cancel-reservation/${reservationId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/cancel-reservation/${reservationId}, with data: ${JSON.stringify({})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/cancel-reservation/${reservationId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getNextApplicationId: (body: ApplicationIDInDTO, onSuccess: ((data : ApplicationIDOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/application-id, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/application-id`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/application-id, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/application-id`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        addOrReplaceGuests: (resourceContractId: number,body: AddOrRemoveGuestsInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/add-guest/${resourceContractId}, with data: ${JSON.stringify({body})}`);
            axios
            .post(`${backendUrl}/api/${slugs}/add-guest/${resourceContractId}`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/${slugs}/add-guest/${resourceContractId}, with data: ${JSON.stringify({body})}`);
             
            axios
                .post(`${backendUrl}/api/${slugs}/add-guest/${resourceContractId}`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        rstFinishOrder: (tableId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending post -> ${backendUrl}/api/rst-waiter/finish-order/${tableId}, with data: ${JSON.stringify({})}`);
            axios
            .post(`${backendUrl}/api/rst-waiter/finish-order/${tableId}`, {},  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending post -> ${backendUrl}/api/rst-waiter/finish-order/${tableId}, with data: ${JSON.stringify({})}`);
             
            axios
                .post(`${backendUrl}/api/rst-waiter/finish-order/${tableId}`, {},  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        changeReservationRoom: (body: ChangeRoomInDTO, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending patch -> ${backendUrl}/api/${slugs}/change-room, with data: ${JSON.stringify({body})}`);
            axios
            .patch(`${backendUrl}/api/${slugs}/change-room`, body,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {body});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending patch -> ${backendUrl}/api/${slugs}/change-room, with data: ${JSON.stringify({body})}`);
             
            axios
                .patch(`${backendUrl}/api/${slugs}/change-room`, body,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {body});
                     
                })
         }); 
        },
        getDownloadDocumentForExpedition: (attachmentUUID: string, onSuccess: ((data : any)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/public/download-attachment/${attachmentUUID}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/public/download-attachment/${attachmentUUID}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/public/download-attachment/${attachmentUUID}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public/download-attachment/${attachmentUUID}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        computePrice: (resourceId: string,sinceInLocalDate: string,tillInLocalDate: string, onSuccess: ((data : ComputePriceOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/${resourceId}/compute-price, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/${resourceId}/compute-price`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([{sinceInLocalDate}, {tillInLocalDate}]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        loadResourceDetails: (listOfResourceUUUID: string[], onSuccess: ((data : RoomDetailOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/room-detail, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/room-detail`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([{listOfResourceUUUID}]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        listResourceGroup: (resourceGroupId: string,resourceGroupType: string, onSuccess: ((data : PensionRoomListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/resource-group/${resourceGroupType}/${resourceGroupId}/list-resources, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/resource-group/${resourceGroupType}/${resourceGroupId}/list-resources`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        get: (onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/public-api/p-cloud, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/public-api/p-cloud`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/public-api/p-cloud, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/p-cloud`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getResourceCalendarInfo: (resourceUUID: string,since: string,till: string, onSuccess: ((data : DayInfo[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/get-calendar-info/${resourceUUID}/${since}/${till}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/get-calendar-info/${resourceUUID}/${since}/${till}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        getDisabledDaysForResourceGroup: (resourceGroup: string,since: string,till: string, onSuccess: ((data : DisabledDayDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];

            console.log(`Sending get -> ${backendUrl}/public-api/get-blocked-days/${resourceGroup}/${since}/${till}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/public-api/get-blocked-days/${resourceGroup}/${since}/${till}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
        
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
        
        },
        getUserInfo: (onSuccess: ((data : UserInfoOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/user, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/user`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/user, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/user`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        listSupplierInvoices: (onSuccess: ((data : SupplierInvoiceListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/supplier-invoice/list, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/supplier-invoice/list`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/supplier-invoice/list, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/supplier-invoice/list`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadSupplierInvoicePaymentInfo: (supplierInvoiceId: number, onSuccess: ((data : SupplierInvoicePaymentInfoOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/supplier-invoice-payment-info/${supplierInvoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/supplier-invoice-payment-info/${supplierInvoiceId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/supplier-invoice-payment-info/${supplierInvoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/supplier-invoice-payment-info/${supplierInvoiceId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getRoomServiceOverview: (resourceGroupUUID: string, onSuccess: ((data : RoomServiceOverviewOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/roomservice-overview/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/roomservice-overview/${resourceGroupUUID}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/roomservice-overview/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/roomservice-overview/${resourceGroupUUID}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        restListMenuItems: (resourceGroupUUID: string,filter: RestListMenuItemFilter,page: Pageable, onSuccess: ((data : PageResponseMenuItemOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([filter, page]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/rest-list-menu-item/${resourceGroupUUID}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([filter, page]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        generateResourceReportPDF: (year: number,quarter: number,resourceGroupUUID: string, onSuccess: ((data : Api2PdfResponse)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/resource-report/pdf/${resourceGroupUUID}/${year}/${quarter}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/resource-report/pdf/${resourceGroupUUID}/${year}/${quarter}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/resource-report/pdf/${resourceGroupUUID}/${year}/${quarter}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/resource-report/pdf/${resourceGroupUUID}/${year}/${quarter}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        computeResourceReport: (year: number,quarter: number,resourceGroupUUID: string, onSuccess: ((data : AccommodationPriceInfo[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/resource-report/data/${resourceGroupUUID}/${year}/${quarter}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/resource-report/data/${resourceGroupUUID}/${year}/${quarter}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/resource-report/data/${resourceGroupUUID}/${year}/${quarter}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/resource-report/data/${resourceGroupUUID}/${year}/${quarter}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getReservationDetail: (reservationid: number, onSuccess: ((data : ResourceContractOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/reservation-detail/${reservationid}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/reservation-detail/${reservationid}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/reservation-detail/${reservationid}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/reservation-detail/${reservationid}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadReceptionOverview: (effectiveDate: string, onSuccess: ((data : ReceptionOverviewOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/reception-overview, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/reception-overview`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([{effectiveDate}]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/reception-overview, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/reception-overview`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([{effectiveDate}]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadPriceListItemByCode: (code: string, onSuccess: ((data : PriceItemOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/pricelist/${code}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/pricelist/${code}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/pricelist/${code}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/pricelist/${code}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        tryToLinkPayments: (onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/payment-link, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/payment-link`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/payment-link, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/payment-link`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovBankAccounts: (onSuccess: ((data : LovBankAccountOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/lov-bank-account, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/lov-bank-account`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/lov-bank-account, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/lov-bank-account`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovListResources: (onSuccess: ((data : LoVWithUUID[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-resources, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-resources`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-resources, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-resources`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovListResourceGroups: (onSuccess: ((data : LoVWithUUID[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-resource-groups, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-resource-groups`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-resource-groups, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-resource-groups`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        listReservationsByResource: (resourceGroupUUID: string,sinceLocalDate: string,tillLocalDate: string, onSuccess: ((data : ListReservationByResourceResourceOccupation[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-reservations-by-resource/${resourceGroupUUID}/${sinceLocalDate}/${tillLocalDate}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-reservations-by-resource/${resourceGroupUUID}/${sinceLocalDate}/${tillLocalDate}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-reservations-by-resource/${resourceGroupUUID}/${sinceLocalDate}/${tillLocalDate}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-reservations-by-resource/${resourceGroupUUID}/${sinceLocalDate}/${tillLocalDate}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        listContracts: (filterDTO: ListContractFilterDTO, onSuccess: ((data : ContractListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-contracts, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-contracts`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([filterDTO]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-contracts, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-contracts`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([filterDTO]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovSynchronizedAccounts: (onSuccess: ((data : ListBankItemsSynchronizedAccount[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-bank-items/lov-synchronized-bank-account, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-bank-items/lov-synchronized-bank-account`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-bank-items/lov-synchronized-bank-account, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-bank-items/lov-synchronized-bank-account`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadBankAccounts: (onSuccess: ((data : BankAccountListOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-bank-accounts, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/list-bank-accounts`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/list-bank-accounts, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/list-bank-accounts`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadInvoicePaymentInfo: (invoiceId: number, onSuccess: ((data : InvoicePaymentInfoOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/invoice-payment-info/${invoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/invoice-payment-info/${invoiceId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/invoice-payment-info/${invoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/invoice-payment-info/${invoiceId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        listInvoices: (onSuccess: ((data : InvoiceListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/invoice-list, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/invoice-list`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/invoice-list, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/invoice-list`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadRentalDetail: (carId: number, onSuccess: ((data : GetRentalDetailOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-rental-detail/${carId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-rental-detail/${carId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-rental-detail/${carId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-rental-detail/${carId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadObjectLabels: (objectType: string,objectId: number, onSuccess: ((data : LabelOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-object-labels/${objectType}/${objectId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-object-labels/${objectType}/${objectId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-object-labels/${objectType}/${objectId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-object-labels/${objectType}/${objectId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetCategories: (resourceGroupUUID: string, onSuccess: ((data : GetMenuItemCategoriesOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-menu-item-categories/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-menu-item-categories/${resourceGroupUUID}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-menu-item-categories/${resourceGroupUUID}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-menu-item-categories/${resourceGroupUUID}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadLabels: (onSuccess: ((data : LabelOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-label, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-label`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-label, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-label`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadInvoiceDetail: (invoiceId: number, onSuccess: ((data : GetInvoiceOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-invoice/${invoiceId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-invoice/${invoiceId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadExpenseById: (expenseId: number, onSuccess: ((data : ExpenseDetailOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-expense/${expenseId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-expense/${expenseId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-expense/${expenseId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-expense/${expenseId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getCompanyRegistration: (onSuccess: ((data : GetCompanyRegistrationOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-company-registration, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/get-company-registration`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/get-company-registration, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/get-company-registration`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        list: (page: number, onSuccess: ((data : Expense[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/${page}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/fakturoid/sync/${page}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/${page}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/fakturoid/sync/${page}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        synchronizeInvoices: (c: Company, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/invoices, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/fakturoid/sync/invoices`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([c]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/invoices, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/fakturoid/sync/invoices`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([c]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        synchonizeExpesesFromFakturoid: (c: Company, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/expenses, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/fakturoid/sync/expenses`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([c]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/fakturoid/sync/expenses, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/fakturoid/sync/expenses`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([c]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getDashboardData: (onSuccess: ((data : DashboardOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/dash-board, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/dash-board`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/dash-board, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/dash-board`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        paySupplierInvoice: (supplierInvoiceId: number, onSuccess: ((data : DomesticTransaction)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/create-payment/${supplierInvoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/create-payment/${supplierInvoiceId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/create-payment/${supplierInvoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/create-payment/${supplierInvoiceId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovContactsWithAccount: (onSuccess: ((data : LovWithAccount[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/contacts/with-account, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/contacts/with-account`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/contacts/with-account, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/contacts/with-account`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        listCars: (onSuccess: ((data : CarListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/car-list, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/car-list`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/car-list, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/car-list`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadCarDetail: (carId: number, onSuccess: ((data : CarDetailOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/car-detail/${carId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/car-detail/${carId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/car-detail/${carId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/car-detail/${carId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        loadBorrowedCars: (onSuccess: ((data : BorrowedCarsListOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/borrowed-cars-list, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/${slugs}/borrowed-cars-list`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/${slugs}/borrowed-cars-list, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/${slugs}/borrowed-cars-list`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetLoggedUserInfo: (onSuccess: ((data : LoggedUserServiceOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/info, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/info`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/info, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/info`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetTableList: (onSuccess: ((data : GetTablesListDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-tables, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/get-tables`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-tables, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/get-tables`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetOrderItems: (tableId: number, onSuccess: ((data : RstOrderItemOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-order-items/${tableId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/get-order-items/${tableId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-order-items/${tableId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/get-order-items/${tableId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetMenuItems: (onSuccess: ((data : GetMenuItemsPerCategoryOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-menu-items, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/get-menu-items`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-menu-items, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/get-menu-items`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetMenuItemsPerCategory: (categoryId: number, onSuccess: ((data : GetMenuItemsPerCategoryOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-menu-items-per-category/${categoryId}, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/get-menu-items-per-category/${categoryId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-menu-items-per-category/${categoryId}, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/get-menu-items-per-category/${categoryId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstGetCategories_1: (onSuccess: ((data : GetCategoriesOutDTO[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-categories, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/rst-waiter/get-categories`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/rst-waiter/get-categories, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/rst-waiter/get-categories`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        lovBankItemType: (onSuccess: ((data : LoVCodeOnly[])=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/lov-payment-type, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/lov-payment-type`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/lov-payment-type, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/lov-payment-type`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getInfo: (onSuccess: ((data : Authentication)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/info, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/info`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/info, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/info`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        hasUserIdentity: (onSuccess: ((data : UserLoginInfoOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/has-identity, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/has-identity`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/has-identity, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/has-identity`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getUserIdentity: (onSuccess: ((data : GetIdentityOutDTO)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/get-identity, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/get-identity`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/get-identity, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/get-identity`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        getCompanyFromAresByICO: (ico: string, onSuccess: ((data : AresResponse)=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending get -> ${backendUrl}/api/ares, with data: ${JSON.stringify({})}`);
            axios
            .get(`${backendUrl}/api/ares`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([{ico}]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending get -> ${backendUrl}/api/ares, with data: ${JSON.stringify({})}`);
             
            axios
                .get(`${backendUrl}/api/ares`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([{ico}]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        deletePriceListItem: (id: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/pricelist/${id}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/${slugs}/pricelist/${id}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/pricelist/${id}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/${slugs}/pricelist/${id}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        deleteResourceContract: (resourceContractId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-reservation/${resourceContractId}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/${slugs}/delete-reservation/${resourceContractId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-reservation/${resourceContractId}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/${slugs}/delete-reservation/${resourceContractId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        deleteInvoice: (invoiceId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/${slugs}/delete-invoice/${invoiceId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-invoice/${invoiceId}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/${slugs}/delete-invoice/${invoiceId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        deleteExpense: (expenseId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-expense/${expenseId}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/${slugs}/delete-expense/${expenseId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/delete-expense/${expenseId}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/${slugs}/delete-expense/${expenseId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        deleteAttachment: (uuid: string, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/attachment/${uuid}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/${slugs}/attachment/${uuid}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/${slugs}/attachment/${uuid}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/${slugs}/attachment/${uuid}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
        rstDeleteOrder: (tableId: number, onSuccess: (()=>void), hashAuthentication: string = null, callbacks: Callbacks ={}):void => {
        const slugs = router.query["slugs"];
            if (hashAuthentication) {
            console.log(`Sending delete -> ${backendUrl}/api/rst-waiter/delete-order/${tableId}, with data: ${JSON.stringify({})}`);
            axios
            .delete(`${backendUrl}/api/rst-waiter/delete-order/${tableId}`,  {
            headers: {
            'Accept-Language': i18n.language,
             Authorization: `Hash ${hashAuthentication}`,  
            },
            params: resolveQueryParams([]),
            })
            .then((response) => {
                if (isSuccess(response)) {
                   // @ts-ignore
                   onSuccess(response.data.payload);

                }
                if (hasAdditionalAction(response)) {
                    if (typeof callbacks.onAdditionalAction === 'function') {
                        callbacks.onAdditionalAction(response.data.payload);
                    }
                }

                if (isConflict(response)) {
                    if (typeof callbacks.onConflict === 'function') {
                        callbacks.onConflict(response.data.payload.entity);
                    }
                }
                if (validationFailed(response)) {
                    if (typeof callbacks.onValidationFailed === 'function') {
                        callbacks.onValidationFailed(response.data.messages);
                    }
                }

                if (entityNotFound(response)) {
                    if (typeof callbacks.onEntityNotFound === 'function') {
                        callbacks.onEntityNotFound(response.data.payload);
                    }
                }
                 
            })
            .catch((error)=>{
                if (typeof callbacks.onError === 'function') {
                    callbacks.onError(error);
                } else
                handleError(error, {});
                 
            })
            } else
            getIdTokenClaims().then((res) => { 
            console.log(`Sending delete -> ${backendUrl}/api/rst-waiter/delete-order/${tableId}, with data: ${JSON.stringify({})}`);
             
            axios
                .delete(`${backendUrl}/api/rst-waiter/delete-order/${tableId}`,  {
                    headers: {
                    'Accept-Language': i18n.language,
         Authorization: `Bearer ${res?.__raw}`,  
                    },
                    params: resolveQueryParams([]),
                })
                .then((response) => {
                    if (isSuccess(response)) {
                        // @ts-ignore
                        onSuccess(response.data.payload);
                        }
                    if (hasAdditionalAction(response)) {
                        if (typeof callbacks.onAdditionalAction === 'function') {
                            callbacks.onAdditionalAction(response.data.payload);
                        }
                    }

                    if (isConflict(response)) {
                        if (typeof callbacks.onConflict === 'function') {
                            callbacks.onConflict(response.data.payload.entity);
                        }
                    }
                    if (validationFailed(response)) {
                        if (typeof callbacks.onValidationFailed === 'function') {
                         callbacks.onValidationFailed(response.data.messages);
                        }
                    }

                    if (entityNotFound(response)) {
                        if (typeof callbacks.onEntityNotFound === 'function') {
                            callbacks.onEntityNotFound(response.data.payload);
                        }
                    }
                     
                })
                .catch((error)=>{
                    if (typeof callbacks.onError === 'function') {
                        callbacks.onError(error);
                    } else
                    handleError(error, {});
                     
                })
         }); 
        },
  };

    return apiMethods;

};
